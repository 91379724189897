<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form" @submit.prevent="ValidateForm">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  @keyup.enter="nextInput"
                  ref="input"
                  dense
                  outlined
                  :label="$t('position.name')"
                  v-model="data.name"
                  :rules="nameRules"
                  required
                  :hint="`${server_errors.name}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>
<!--            <v-col cols="12" md="12">-->
<!--              <vuetify-money-->
<!--                  dense-->
<!--                  :outlined="true"-->
<!--                  :options="this.$store.state.formatSalaryNumber"-->
<!--                  :clearable="true"-->
<!--                  :label="$t('position.salary')"-->
<!--                  type="number"-->
<!--                  v-model="data.salary"-->
<!--                  :rules="nameRules"-->
<!--                  required-->
<!--                  :hint="`${server_errors.salary}`"-->
<!--              />-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="12">-->
<!--              <v-select-->
<!--                  dense-->
<!--                  outlined-->
<!--                  :label="$t('position.currency')"-->
<!--                  :items="listCurrency"-->
<!--                  item-text="name"-->
<!--                  item-value="id"-->
<!--                  v-model="data.currency_id"-->
<!--                  :rules="nameRules"-->
<!--                  required-->
<!--              >-->
<!--              </v-select>-->
<!--            </v-col>-->
            <v-col cols="8">
              <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
                {{ $t("position.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      // businessType: [],
      nameRules: [
        v => !!v || 'field is required...'
      ],
      listCurrency: [],
      data: {
        name: "",
        // salary: "",
        // currency_id: "",
      },
      server_errors: {
        name: "",
        // salary: "",
      }

    }
  },
  methods: {
    nextInput() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },
    // fetchCurrency() {
    //   this.$axios.get(`company/currency`).then((res) => {
    //     if (res.data.code === 200) {
    //       this.listCurrency = res.data.data;
    //     }
    //   }).catch(() => {
    //
    //   })
    // },
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      this.$axios.post(`company/position`, this.data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$router.push({name: "position.index"})
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    },
  },
  created() {
    // this.fetchCurrency();
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 400px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
